import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Header, Footer, SEO, Slideshow } from "../components"
import "./about.scss"

export default () => {
  const {
    markdownRemark: { html },
  } = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { path: { eq: "/about" } }) {
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          path
          title
        }
      }
    }
  `)

  let htmlCopy = html

  const imgTags = htmlCopy.match(/<img(.[^>]*)>/g)
  htmlCopy = htmlCopy.replace(/<img(.[^>]*)>/g, "")

  const images = []
  if (imgTags)
    imgTags.map(el => {
      const src = el.match(/src\s*=\s*"(.+?)"/g)[0].slice(5, -1)
      const alt = el.match(/alt\s*=\s*"(.+?)"/g)[0].slice(5, -1)
      const title = el.match(/title\s*=\s*"(.+?)"/g)[0].slice(7, -1)
      images.push([src, alt, title])
    })

  return (
    <div id="about">
      <SEO title="Discrit" description="Art in Conversation" />
      <Header />
      <main>
        <Slideshow images={images} background={images[0]} />
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: htmlCopy }}
        />
      </main>
      <Footer />
    </div>
  )
}
